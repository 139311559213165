import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../../common/services/basic.service';
import { API_REGION_URL } from '../../connection.data';

@Injectable()
export class RegionService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_REGION_URL;
    }
}